import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = ["aria-label"]
const _hoisted_2 = { class: "item-list" }
const _hoisted_3 = ["src", "alt"]
const _hoisted_4 = {
  key: 1,
  class: "icon-circle_check_outline",
  "aria-hidden": "true"
}
const _hoisted_5 = ["for"]
const _hoisted_6 = ["src", "alt"]
const _hoisted_7 = {
  key: 0,
  class: "card-footer"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_HeadingComponent = _resolveComponent("HeadingComponent")!
  const _component_RadioButton = _resolveComponent("RadioButton")!
  const _component_Checkbox = _resolveComponent("Checkbox")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_HeadingComponent, {
      "sub-heading": true,
      divider: false,
      title: _ctx.title
    }, {
      right: _withCtx(() => [
        _withDirectives((_openBlock(), _createBlock(_component_Button, {
          class: _normalizeClass(["p-button-rounded p-button-only-icon p-button-only-icon--dark", _ctx.editMode ? 'p-disabled' : '']),
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.editMode = !_ctx.editMode))
        }, {
          default: _withCtx(() => [
            _createElementVNode("i", {
              class: "icon-edit",
              "aria-label": _ctx.$t('common.edit'),
              "aria-hidden": "true"
            }, null, 8, _hoisted_1)
          ]),
          _: 1
        }, 8, ["class"])), [
          [
            _directive_tooltip,
            _ctx.$t('common.edit'),
            void 0,
            { bottom: true }
          ]
        ])
      ]),
      _: 1
    }, 8, ["title"]),
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.mutableItems, (item) => {
        return (_openBlock(), _createElementBlock("div", {
          key: item.id,
          class: _normalizeClass(["item", { 'item-margin': _ctx.editMode || _ctx.showItem(item) }])
        }, [
          (_ctx.showItem(item))
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                (_ctx.hasIcons)
                  ? (_openBlock(), _createElementBlock("img", {
                      key: 0,
                      src: '/assets/images/alergens/' + _ctx.alergenIcon(item.id),
                      alt: item.id + ' icon',
                      class: "item-icon"
                    }, null, 8, _hoisted_3))
                  : (_openBlock(), _createElementBlock("i", _hoisted_4)),
                _createElementVNode("p", null, _toDisplayString(item.text), 1)
              ], 64))
            : _createCommentVNode("", true),
          (_ctx.editMode)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                (_ctx.selectionType === 'radio')
                  ? (_openBlock(), _createBlock(_component_RadioButton, {
                      key: 0,
                      id: item.id,
                      modelValue: _ctx.selectedRadioOption,
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selectedRadioOption) = $event)),
                      name: item.id,
                      value: item,
                      class: "item-choice"
                    }, null, 8, ["id", "modelValue", "name", "value"]))
                  : _createCommentVNode("", true),
                (_ctx.selectionType === 'checkbox')
                  ? (_openBlock(), _createBlock(_component_Checkbox, {
                      key: 1,
                      id: item.id,
                      modelValue: _ctx.selectedCheckboxOptions,
                      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.selectedCheckboxOptions) = $event)),
                      name: item.id,
                      value: item,
                      class: "item-choice",
                      onChange: _cache[3] || (_cache[3] = ($event: any) => (_ctx.onValueChanged($event)))
                    }, null, 8, ["id", "modelValue", "name", "value"]))
                  : _createCommentVNode("", true),
                _createElementVNode("label", {
                  for: item.id,
                  class: "label-with-icon"
                }, [
                  (_ctx.hasIcons)
                    ? (_openBlock(), _createElementBlock("img", {
                        key: 0,
                        src: '/assets/images/alergens/' + _ctx.alergenIcon(item.id),
                        alt: item.id + ' icon',
                        class: "item-icon"
                      }, null, 8, _hoisted_6))
                    : _createCommentVNode("", true),
                  _createElementVNode("span", null, _toDisplayString(item.text), 1)
                ], 8, _hoisted_5)
              ], 64))
            : _createCommentVNode("", true)
        ], 2))
      }), 128))
    ]),
    (_ctx.editMode)
      ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
          _createVNode(_component_Button, {
            class: "p-button-secondary p-button-medium",
            label: _ctx.$t('common.cancel'),
            onClick: _ctx.onCancelEdition
          }, null, 8, ["label", "onClick"]),
          _createVNode(_component_Button, {
            class: "p-button p-button-info p-button-medium",
            loading: _ctx.waitSubmit,
            label: _ctx.$t('common.save'),
            onClick: _ctx.onSubmit
          }, null, 8, ["loading", "label", "onClick"])
        ]))
      : _createCommentVNode("", true)
  ], 64))
}