
import { defineComponent, onMounted, PropType, Ref, ref } from 'vue';
import { Intolerances, PatientInfoItem } from '@/models/PatientInfo';
import HeadingComponent from '../HeadingComponent.vue';

export default defineComponent({
  components: { HeadingComponent },
  emits: ['patientInfoChanged'],
  props: {
    title: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
    options: {
      type: Array as PropType<PatientInfoItem[]>,
      required: true,
    },
    selectionType: {
      type: String,
      default: 'checkbox',
    },
    hasIcons: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const editMode = ref(false);
    const waitSubmit = ref(false);
    const mutableItems: Ref<PatientInfoItem[]> = ref([]);
    const emptyItem = {
      id: '',
      text: '',
      value: false,
    };
    const selectedRadioOption: Ref<PatientInfoItem> = ref(emptyItem);
    const selectedCheckboxOptions: Ref<PatientInfoItem[]> = ref([]);

    const isItemSelected = (item: PatientInfoItem) => {
      switch (props.selectionType) {
        case 'checkbox':
          return selectedCheckboxOptions.value.includes(item);
        case 'radio':
          return selectedRadioOption.value === item;
        default:
          return false;
      }
    };

    const showItem = (item: PatientInfoItem) => {
      return editMode.value === false && isItemSelected(item);
    };

    const setInitialVaules = () => {
      mutableItems.value = [...props.options];
      selectedRadioOption.value = mutableItems.value.find((i) => i.value === true) || emptyItem;
      selectedCheckboxOptions.value = mutableItems.value.filter((i) => i.value === true);
    };

    onMounted(() => setInitialVaules());

    const alergenIcon = (type: string) => {
      switch (type) {
        case Intolerances.NO:
          return 'none.svg';
        case Intolerances.LUPINE:
          return 'lupine.svg';
        case Intolerances.CELERY:
          return 'celery.svg';
        case Intolerances.CRUSTACEANS:
          return 'crustaceans.svg';
        case Intolerances.DAIRY:
          return 'dairy.svg';
        case Intolerances.EGGS:
          return 'eggs.svg';
        case Intolerances.GLUTEN:
          return 'gluten.svg';
        case Intolerances.MOLLUSKS:
          return 'mollusks.svg';
        case Intolerances.NUTS:
          return 'nuts.svg';
        case Intolerances.PEANUTS:
          return 'peanuts.svg';
        case Intolerances.SESAME:
          return 'sesame.svg';
        case Intolerances.SOY:
          return 'soy.svg';
        case Intolerances.SULPHITE:
          return 'sulphite.svg';
        case Intolerances.LACTOSE:
          return 'dairy.svg';
      }
    };

    const onSubmit = async () => {
      waitSubmit.value = true;
      let newValues: string | string[] = mutableItems.value.filter((i) => isItemSelected(i)).map((i) => i.id);
      if (props.selectionType === 'radio') {
        newValues = newValues[0];
      }
      emit('patientInfoChanged', props.type, newValues);
      waitSubmit.value = false;
      editMode.value = false;
    };

    const onCancelEdition = async () => {
      editMode.value = false;
      setInitialVaules();
    };

    const onValueChanged = ($event: any) => {
      const inputId = $event.target.parentElement.firstChild.firstChild.id;

      if (inputId === 'NO') {
        selectedCheckboxOptions.value = selectedCheckboxOptions.value.filter((i) => i.id === 'NO');
      }

      if (inputId !== 'NO') {
        selectedCheckboxOptions.value = selectedCheckboxOptions.value.filter((i) => i.id !== 'NO');
      }
    };

    return {
      alergenIcon,
      editMode,
      isItemSelected,
      mutableItems,
      selectedCheckboxOptions,
      selectedRadioOption,
      showItem,
      waitSubmit,
      onSubmit,
      onCancelEdition,
      onValueChanged,
    };
  },
});
