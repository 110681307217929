import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createVNode as _createVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "l-container"
}
const _hoisted_2 = { class: "p-grid--patient-info" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_EmptyState = _resolveComponent("EmptyState")!
  const _component_CgmPatientInfoCard = _resolveComponent("CgmPatientInfoCard")!

  return (_ctx.ismedicalTestStateStarted)
    ? (_openBlock(), _createBlock(_component_EmptyState, {
        key: 0,
        heading: _ctx.$t('cgmDetail.not-started-title'),
        icon: "icon-loading",
        text: _ctx.$t('cgmDetail.not-started-message'),
        textAtBottom: true
      }, null, 8, ["heading", "text"]))
    : (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item, key) => {
            return (_openBlock(), _createElementBlock("div", {
              key: key,
              class: "card"
            }, [
              _createVNode(_component_CgmPatientInfoCard, {
                type: key,
                title: _ctx.$t(`cgm-patient-info.${key}.title`),
                options: item,
                selectionType: ['dietType', 'nutritionist'].includes(key) ? 'radio' : 'checkbox',
                hasIcons: key === 'foodIntolerances',
                onPatientInfoChanged: _ctx.onPatientInfoChanged
              }, null, 8, ["type", "title", "options", "selectionType", "hasIcons", "onPatientInfoChanged"])
            ]))
          }), 128))
        ])
      ]))
}