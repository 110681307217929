
import { Foods, Intolerances, Nutritionist, Objectives } from '@/models/PatientInfo';
import { DietType, MedicalTest, MedicalTestState } from '@/models/MedicalTest';
import { computed, defineComponent, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import CgmPatientInfoCard from './CgmPatientInfoCard.vue';
import { useCgmDetailStore } from '@/store/cgmDetail.module';
import { ServiceError } from '@/services/util/ServiceError';
import { useToast } from 'primevue/usetoast';
import { medicalTestService } from '@/services/MedicalTestService';
import EmptyState from '../EmptyState.vue';
export default defineComponent({
  components: { CgmPatientInfoCard, EmptyState },
  setup() {
    const { t } = useI18n();
    const storeCGM = useCgmDetailStore();
    const toast = useToast();
    const medicalTest = ref(storeCGM.getMedicalTestSelected as MedicalTest);

    const getInfoPropertyName = (type: Record<string, string>) => {
      switch (type) {
        case Objectives:
          return 'objectives';
        case Nutritionist:
          return 'nutritionist';
        case Intolerances:
          return 'foodIntolerances';
        case Foods:
          return 'foods';
        case DietType:
        default:
          return 'dietType';
      }
    };

    const getInfoValue = (type: Record<string, string>, key: string) => {
      const property = medicalTest.value[getInfoPropertyName(type)];
      if (property === null) {
        return false;
      }
      if (Array.isArray(property)) {
        return property.includes(key as never);
      }

      return property === key;
    };

    const buildInfoArray = (type: Record<string, string>) => {
      return Object.keys(type).map((k) => ({
        id: k,
        text: t(`cgm-patient-info.${getInfoPropertyName(type)}.${k}`),
        value: getInfoValue(type, k),
      }));
    };

    const onPatientInfoChanged = async (type: string, values: string[]) => {
      medicalTest.value = { ...medicalTest.value, ...{ [type]: values } };
      const result = await medicalTestService.update(
        medicalTest.value.patientId,
        medicalTest.value.id,
        medicalTest.value,
      );
      if (result instanceof ServiceError) {
        toast.add({
          severity: 'error',
          summary: `${t('messages.notifications.errorEditPatient')} ${t('messages.notifications.tryLater')}`,
          life: 3000,
        });
      } else {
        storeCGM.setMedicalTestSelected(medicalTest.value);
        toast.add({ severity: 'success', summary: `${t('messages.notifications.successEditPatient')}`, life: 3000 });
      }
    };

    const items = {
      objectives: buildInfoArray(Objectives),
      foods: buildInfoArray(Foods),
      nutritionist: buildInfoArray(Nutritionist),
      foodIntolerances: buildInfoArray(Intolerances),
      dietType: buildInfoArray(DietType),
    };

    const ismedicalTestStateStarted = computed(
      () => !medicalTest.value || medicalTest.value?.state === MedicalTestState.STARTED,
    );

    return { items, getInfoPropertyName, onPatientInfoChanged, ismedicalTestStateStarted };
  },
});
